import React from "react"
import StyledCardList from "./style/card-list"
import { FaArrowRight } from "react-icons/fa"

const CardList = ({ data }) => {
    return (
        <StyledCardList>
            <div className="container">
                {data.map((d, i) => {
                    return (
                        <div className="card-wrapper" key={i}>
                            <figure>
                                <img src={d.image} alt={d.title} />
                            </figure>
                            <div className="content">
                                <h2>
                                    <a href={d.slug}>
                                        {d.title}
                                    </a>
                                </h2>
                                <p>
                                    {d.description}
                                </p>
                                <a href={d.slug}>
                                    Read More
                                    <FaArrowRight />
                                </a>
                            </div>
                        </div>
                    )
                })}
            </div>
        </StyledCardList>
    )
};

export default CardList
