import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { SEO } from "../components/seo"
import CardList from "../components/common/CardList"
import bannerImg from "../assets/images/hotel-consultancy.jpg"
import Banner from "../components/common/Banner"

const HospitalityServices = ({ data }) => {
  const hospitality = [];
  const imgPath = process.env.GATSBY_IMAGE_URL
  data.hospitality.edges.forEach(edge => {
    hospitality.push({
      slug: edge.node.frontmatter.slug,
      title: edge.node.frontmatter.title,
      description: edge.node.frontmatter.description,
      image: `${imgPath}${edge.node.frontmatter.slug.toLowerCase()}.jpg`
    })
  });
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Our Services', link: '/our-services' },
    { name: 'Hotel Consultancy', link: '' }
  ]
  return (
    <Layout>
      <Banner
        data="Hotel Consultancy"
        image={bannerImg}
        crumbs={crumbs}
      />
      <CardList data={hospitality} />
    </Layout>
  )
};

export default HospitalityServices
export const Head = () => (
  <SEO
    title="Hotel Consultancy - Housefull Events & Hospitality Management"
    description="Enhance operations, elevate guest experiences and drive profitability with tailored strategies and solutions of Housefull Events hospitality / hotel consultancy services."
  />
)
export const pageQuery = graphql`
  query HospitalityQuery {
    hospitality: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "hospitality-service-details" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            template
            title
            description
          }
        }
      }
    }
  }
`
